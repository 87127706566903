/***************************************************************************************************
 * Load `$localize` onto the global scope - used if i18n tags appear in Angular templates.
 */
import '@angular/localize/init';
// This file includes polyfills needed by Angular 2 and is loaded before
// the app. You can add your own extra polyfills to this file.

import 'zone.js';

import 'url-polyfill';

// Polyfill to use svg icons from external resource
import 'svgxuse';

// ng2-dragula -> crossvent bug. https://github.com/angular/angular-cli/issues/10305
(window as any).global = window;
(window as any).process = {};
// @ts-ignore
window.Buffer = window.Buffer || require('buffer').Buffer;

// IE10 and IE11 requries
import 'url-polyfill';

if (!HTMLCanvasElement.prototype.toBlob) {
  Object.defineProperty(HTMLCanvasElement.prototype, 'toBlob', {
    value(callback, type, quality) {
      const dataURL = this.toDataURL(type, quality).split(',')[1];
      setTimeout(() => {
        const binStr = atob(dataURL);
        const len = binStr.length;
        const arr = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
          arr[i] = binStr.charCodeAt(i);
        }
        callback(new Blob([arr], { type: type || 'image/png' }));
      });
    },
  });
}
// util -> https://github.com/angular/angular-cli/issues/20819
(window as any).process = {
  env: { DEBUG: undefined },
};
